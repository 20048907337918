const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setData(state, payload) {
    state.data = payload.data;
    state.total = payload.total;
  },
  setErrors(state, errors) {
    state.errors = errors;
  },
  setInProcess(state, inProcess) {
    state.inProcess = inProcess;
  },
  setSearch(state, value) {
    state.search = value;
  },
  setGroup(state, value) {
    state.group = value;
  },
  setStatus(state, value) {
    state.status = value;
  },
  setVacant(state, value) {
    state.vacant = value;
  },
  setSortBy(state, value) {
    state.sortBy = value;
  },
  setPage(state, value) {
    state.page = value;
  },
  setPerPage(state, value) {
    state.perPage = value;
  },
  setDefaults(state) {
    state.page = 1;
    state.perPage = "8";
    state.group = "";
    state.sortBy = "updatedAt_DESC";
    state.status = "";
    state.search = "";
    state.vacant = "";
  },
  setUploadInProcess(state, loading) {
    state.uploadInProcess = loading;
  },
  setLoadingFunctions(state, loading) {
    state.loadingFunctions = loading;
  },
  setLoadingAppHelpGroups(state, loading) {
    state.loadingAppHelpGroups = loading;
  },
  setCreateInProcess(state, inProcess) {
    state.create.inProcess = inProcess;
  },
  setCreateErrors(state, payload) {
    state.create.errors = payload.errors;
  },
  resetCreateErrors(state) {
    state.create.errors = [];
  },
  setEditLoading(state, loading) {
    state.edit.loading = loading;
  },
  setEditInProcess(state, inProcess) {
    state.edit.inProcess = inProcess;
  },
  setEditErrors(state, errors) {
    state.edit.errors = errors;
  },
};

export default mutations;
