import { ifAuthenticated } from "@/router/auth";

const zdsGenerallyKlartext = [
  {
    path: "/zds/generally/klartext",
    name: "ZDSGenerallyKlartext",
    component: () =>
      import("@/modules/zds/generally/klartext/views/Klartext.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_generally_klartext" },
  },
  {
    path: "/zds/generally/klartext/create",
    name: "ZDSGenerallyKlartextCreate",
    component: () =>
      import("@/modules/zds/generally/klartext/views/KlartextCreate.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_generally_klartext" },
  },
  {
    path: "/zds/generally/klartext/:id/edit",
    name: "ZDSGenerallyKlartextEdit",
    component: () =>
      import("@/modules/zds/generally/klartext/views/KlartextEdit.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_generally_klartext" },
  },
];

export default zdsGenerallyKlartext;
