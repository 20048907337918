import api from "@/api";
import _ from "lodash";

const actions = {
  update({ commit, dispatch }, payload) {
    const { id, data } = payload;

    return new Promise((resolve, reject) => {
      commit("setInProcess", true);
      commit("setErrors", []);
      api
        .patch(
          _.join(["/v1/administration/users", encodeURI(id), "password"], "/"),
          data
        )
        .then((response) => {
          if (response.data.success) {
            commit("setInProcess", false);
            resolve(response);
          } else {
            commit("setErrors", response.data.payload.errors);
            commit("setInProcess", false);
            reject(response);
          }
        })
        .catch((err) => {
          commit("setInProcess", false);
          dispatch("gobalError", err, { root: true });
        });
    });
  },
};

export default actions;
