import changePassword from "@/modules/zds/administration/members/store/modal/changePassword/";

const state = () => ({});

const getters = {};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    changePassword,
  },
};
