const state = () => ({
  errors: [],
  loading: false,
  uploadInProcess: false,
  data: [],
  total: 0,
  search: "",
  status: "",
  sendStatus: "",
  group: "",
  sendStart: null,
  sendEnd: null,
  archived: "",
  sortBy: "updatedAt_DESC",
  page: 1,
  perPage: "10",
  inProcess: false,
  loadingSenderContacts: false,
  create: {
    inProcess: false,
    errors: [],
  },
  edit: {
    loading: false,
    inProcess: false,
    errors: [],
  },
  hints: {
    title: "",
    subject: "",
    groups: "",
    statusCodes: "",
    senderType: "",
    senderGroup: "",
    senderContact: "",
    status: "",
    sendOption: "",
    scheduledAt: "",
    messageHTML: "",
    sendStatus: "",
    bannerShow: "",
    bannerClickUrl: "",
  },
  options: {
    status: [
      { name: "Freigegeben", value: "APPROVED" },
      { name: "In Bearbeitung", value: "IN_PROCESS" },
      { name: "Entwurf", value: "DRAFT" },
    ],
    sendStatus: [
      { name: "Wartet", value: "WAITED" },
      { name: "In Bearbeitung", value: "IN_PROCESS" },
      { name: "Abgeschlossen", value: "COMPLETED" },
      { name: "Abgebrochen", value: "CANCELLED" },
    ],
    sortBy: [
      { name: "Sortierung: Geändert (absteigend)", value: "updatedAt_DESC" },
      { name: "Sortierung: Geändert (aufsteigend)", value: "updatedAt_ASC" },
      { name: "Sortierung: Angelegt (absteigend)", value: "createdAt_DESC" },
      { name: "Sortierung: Angelegt (aufsteigend)", value: "createdAt_ASC" },
    ],
    perPage: ["10", "20", "30", "40", "50", "100"],
  },
});

export default state;
