import { ifAuthenticated } from "@/router/auth";

const zdsAdministrationLocalGroups = [
  {
    path: "/zds/administration/localgroups",
    name: "ZDSAdministrationLocalGroups",
    component: () =>
      import("@/modules/zds/administration/localGroups/views/LocalGroups.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_administration_localgroups" },
  },
  {
    path: "/zds/administration/localgroups/create",
    name: "ZDSAdministrationLocalGroupsCreate",
    component: () =>
      import("@/modules/zds/administration/localGroups/views/LocalGroupsCreate.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_administration_localgroups" },
  },
  {
    path: "/zds/administration/localgroups/:id/edit",
    name: "ZDSAdministrationLocalGroupsEdit",
    component: () =>
      import("@/modules/zds/administration/localGroups/views/LocalGroupsEdit.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_administration_localgroups" },
  },
];

export default zdsAdministrationLocalGroups;
