const state = () => ({
  errors: [],
  loading: false,
  uploadInProcess: false,
  data: [],
  total: 0,
  search: "",
  group: "",
  status: "",
  vacant: "",
  sortBy: "updatedAt_DESC",
  page: 1,
  perPage: "8",
  inProcess: false,
  loadingFunctions: false,
  loadingAppHelpGroups: false,
  create: {
    inProcess: false,
    errors: [],
  },
  edit: {
    loading: false,
    inProcess: false,
    errors: [],
  },
  hints: {
    status: "",
    vacant: "",
    salutation: "",
    forename: "",
    surname: "",
    zdsFunctionId: "",
    group: "",
    order: "",
    email: "",
    telephone: "",
    mobile: "",
    street: "",
    postcode: "",
    place: "",
    ownImageShow: "",
    zdsAppHelpGroups: "",
  },
  options: {
    vacant: [
      { name: "Ja", value: "YES" },
      { name: "Nein", value: "NO" },
    ],
    status: [
      { name: "Online", value: "ONLINE" },
      { name: "Offline", value: "OFFLINE" },
    ],
    sortBy: [
      { name: "Sortierung: Geändert (absteigend)", value: "updatedAt_DESC" },
      { name: "Sortierung: Geändert (aufsteigend)", value: "updatedAt_ASC" },
      { name: "Sortierung: Angelegt (absteigend)", value: "createdAt_DESC" },
      { name: "Sortierung: Angelegt (aufsteigend)", value: "createdAt_ASC" },
      { name: "Sortierung: Reihenfolge (absteigend)", value: "order_DESC" },
      { name: "Sortierung: Reihenfolge (aufsteigend)", value: "order_ASC" },
    ],
    perPage: ["8", "16", "24", "32", "40", "80"],
  },
});

export default state;
