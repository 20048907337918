const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setInProcess(state, loading) {
    state.inProcess = loading;
  },
  setErrors(state, errors) {
    state.errors = errors;
  },
  setData(state, data) {
    state.data = data;
  },
};

export default mutations;
