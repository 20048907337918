import { ifAuthenticated } from "@/router/auth";

const zdsWebsiteBlogs = [
  {
    path: "/zds/website/blogs",
    name: "ZDSWebsiteBlogs",
    component: () =>
      import("@/modules/zds/website/blogs/views/Blogs.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_website_blogs" },
  },
  {
    path: "/zds/website/blogs/create",
    name: "ZDSWebsiteBlogsCreate",
    component: () =>
      import("@/modules/zds/website/blogs/views/BlogsCreate.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_website_blogs" },
  },
  {
    path: "/zds/website/blogs/:id/edit",
    name: "ZDSWebsiteBlogsEdit",
    component: () =>
      import("@/modules/zds/website/blogs/views/BlogsEdit.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_website_blogs" },
  },
];

export default zdsWebsiteBlogs;
