import store from "@/store";

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next();
    return;
  }
  next("/");
};

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    if (store.getters.allowModules.includes(to.meta.moduleId)) {
      next();
      return;
    } else {
      next("/noaccess");
      return;
    }
  }
  next("/login");
};

export { ifNotAuthenticated, ifAuthenticated };
