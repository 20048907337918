import { ifAuthenticated } from "@/router/auth";

const zdsGenerallySweepDistricts = [
  {
    path: "/zds/generally/sweepdistricts",
    name: "ZDSGenerallySweepDistricts",
    component: () =>
      import("@/modules/zds/generally/sweepdistricts/views/SweepDistricts.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_generally_sweepdistricts" },
  },
  {
    path: "/zds/generally/sweepdistricts/create",
    name: "ZDSGenerallySweepDistrictsCreate",
    component: () =>
      import("@/modules/zds/generally/sweepdistricts/views/SweepDistrictsCreate.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_generally_sweepdistricts" },
  },
  {
    path: "/zds/generally/sweepdistricts/:id/edit",
    name: "ZDSGenerallySweepDistrictsEdit",
    component: () =>
      import("@/modules/zds/generally/sweepdistricts/views/SweepDistrictsEdit.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_generally_sweepdistricts" },
  },
];

export default zdsGenerallySweepDistricts;
