const mutations = {
  setErrors(state, errors) {
    state.errors = errors;
  },
  setInProcess(state, inProcess) {
    state.inProcess = inProcess;
  },
  setUploadInProcess(state, loading) {
    state.uploadInProcess = loading;
  },
  setDefaults(state) {
    state.inProcess = false;
    state.uploadInProcess = false;
  },
};

export default mutations;
