const state = () => ({
  errors: [],
  inProcess: false,
  uploadInProcess: false,
  hints: {
    order: "",
    label: "",
    view: "",
  },
});

export default state;
