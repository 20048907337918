import selectMembers from "@/modules/zds/administration/lists/store/modal/selectMembers/";

const state = () => ({});

const getters = {};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    selectMembers,
  },
};
