import { ifAuthenticated } from "@/router/auth";

const zdsAppConferences = [
  {
    path: "/zds/app/conferences",
    name: "ZDSAppConferences",
    component: () =>
      import("@/modules/zds/app/conferences/views/Conferences.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_app_conferences" },
  },
  {
    path: "/zds/app/conferences/create",
    name: "ZDSAppConferencesCreate",
    component: () =>
      import("@/modules/zds/app/conferences/views/ConferencesCreate.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_app_conferences" },
  },
  {
    path: "/zds/app/conferences/:id/edit",
    name: "ZDSAppConferencesEdit",
    component: () =>
      import("@/modules/zds/app/conferences/views/ConferencesEdit.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_app_conferences" },
  },
];

export default zdsAppConferences;
