import changePassword from "@/store/modules/profile/modal/changePassword/";
import changeEmail from "@/store/modules/profile/modal/changeEmail/";
import changeMobile from "@/store/modules/profile/modal/changeMobile/";

const state = () => ({});

const getters = {};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    changePassword,
    changeEmail,
    changeMobile
  },
};
