import state from "./state";
import getters from "./getters";
import actions from "./actions";
import mutations from "./mutations";

import modal from "@/store/modules/administrationUsers/modal/";

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    modal
  },
};
