const state = () => ({
  errors: [],
  loading: true,
  data: [],
  total: 0,
  search: "",
  state: "",
  status: "",
  archived: "",
  sortBy: "updatedAt_DESC",
  page: 1,
  perPage: "10",
  inProcess: false,
  create: {
    inProcess: false,
    errors: [],
  },
  edit: {
    loading: false,
    inProcess: false,
    errors: [],
  },
  hints: {
    status: "",
    archived: "",
    title: "",
    zdsStateId: "",
    authority: "",
    beginAt: "",
    deadlineAt: "",
    link: "",
    description: "",
    type: "",
  },
  options: {
    status: [
      { name: "Eingegangen", value: "CREATED" },
      { name: "In Bearbeitung", value: "IN_PROCESS" },
      { name: "Freigegeben", value: "APPROVED" },
      { name: "Storniert", value: "CANCELLED" },
      { name: "Abgelaufen", value: "EXPIRED" },
    ],
    sortBy: [
      { name: "Sortierung: Geändert (absteigend)", value: "updatedAt_DESC" },
      { name: "Sortierung: Geändert (aufsteigend)", value: "updatedAt_ASC" },
      { name: "Sortierung: Angelegt (absteigend)", value: "createdAt_DESC" },
      { name: "Sortierung: Angelegt (aufsteigend)", value: "createdAt_ASC" },
      {
        name: "Sortierung: Schlussdatum (absteigend)",
        value: "deadlineAt_DESC",
      },
      {
        name: "Sortierung: Schlussdatum (aufsteigend)",
        value: "deadlineAt_ASC",
      },
    ],
    perPage: ["10", "20", "30", "40", "50", "100"],
  },
});

export default state;
