import { ifAuthenticated } from "@/router/auth";

const zdsAdministrationMembers = [
  {
    path: "/zds/administration/members",
    name: "ZDSAdministrationMembers",
    component: () =>
      import("@/modules/zds/administration/members/views/Members.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_administration_members" },
  },
  {
    path: "/zds/administration/members/create",
    name: "ZDSAdministrationMembersCreate",
    component: () =>
      import("@/modules/zds/administration/members/views/MembersCreate.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_administration_members" },
  },
  {
    path: "/zds/administration/members/:id/edit",
    name: "ZDSAdministrationMembersEdit",
    component: () =>
      import("@/modules/zds/administration/members/views/MembersEdit.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_administration_members" },
  },
];

export default zdsAdministrationMembers;
