import { ifAuthenticated } from "@/router/auth";

const zdsMediadataImages = [
  {
    path: "/zds/mediadata/images",
    name: "ZDSMediadataImages",
    component: () => import("@/modules/zds/mediadata/images/views/Images.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_mediadata_images" },
  },
  {
    path: "/zds/mediadata/images/add",
    name: "ZDSMediadataImagesAdd",
    component: () =>
      import("@/modules/zds/mediadata/images/views/ImagesAdd.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_mediadata_images" },
  },
];

export default zdsMediadataImages;
