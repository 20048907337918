const state = () => ({
  errors: [],
  loading: false,
  uploadInProcess: false,
  data: [],
  total: 0,
  search: "",
  type: "",
  category: "",
  status: "",
  sortBy: "updatedAt_DESC",
  page: 1,
  perPage: "5",
  inProcess: false,
  create: {
    inProcess: false,
    errors: [],
  },
  edit: {
    loading: false,
    inProcess: false,
    errors: [],
  },
  hints: {
    zdsWebsiteBlogCategorieId: "",
    internalLabel: "",
    headline: "",
    introduction: "",
    description: "",
    publishedAt: "",
    type: "",
    status: "",
    slug: "",
    metaTitle: "",
    metaDescription: "",
    bannerImageShow: "",
    bannerImageAltText:"",
    bannerImageCopyright: "",
    selectedDocumentFilename: "",
  },
  options: {
    type: [
      { name: "Öffentlich", value: "PUBLIC" },
      { name: "Paywall", value: "PAYWALL" },
    ],
    status: [
      { name: "Veröffentlicht", value: "ONLINE" },
      { name: "In Bearbeitung", value: "IN_PROCESS" },
      { name: "Entwurf", value: "DRAFT" },
      { name: "Ausgeblendet", value: "OFFLINE" },
    ],
    sortBy: [
      { name: "Sortierung: Geändert (absteigend)", value: "updatedAt_DESC" },
      { name: "Sortierung: Geändert (aufsteigend)", value: "updatedAt_ASC" },
      { name: "Sortierung: Angelegt (absteigend)", value: "createdAt_DESC" },
      { name: "Sortierung: Angelegt (aufsteigend)", value: "createdAt_ASC" },
      {
        name: "Sortierung: Publizierung (absteigend)",
        value: "publishedAt_DESC",
      },
      {
        name: "Sortierung: Publizierung (aufsteigend)",
        value: "publishedAt_ASC",
      },
    ],
    perPage: ["5", "10", "15", "20", "25", "50"],
  },
});

export default state;
