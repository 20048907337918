import axios from "axios";

const get = (url, params = {}) =>
  new Promise((resolve, reject) => {
    axios
      .get("https://api.nero.zds.net" + url, { params, timeout: 60000 })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

const post = (url, postData = {}) =>
  new Promise((resolve, reject) => {
    axios
      .post("https://api.nero.zds.net" + url, postData, { timeout: 60000 })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

const patch = (url, postData = {}) =>
  new Promise((resolve, reject) => {
    axios
      .patch("https://api.nero.zds.net" + url, postData, { timeout: 60000 })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

const uploadFile = (url, file) =>
  new Promise((resolve, reject) => {
    const data = new FormData();
    data.append("upfile", file);

    axios
      .post("https://api.nero.zds.net" + url, data, {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
        timeout: 120000,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

const uploadFiles = (url, files) =>
  new Promise((resolve, reject) => {
    const data = new FormData();
    files.forEach((file) => {
      data.append("upfiles[]", file);
    });

    axios
      .post("https://api.nero.zds.net" + url, data, {
        headers: {
          "Content-Type": `multipart/form-data; boundary=${data._boundary}`,
        },
        timeout: 120000,
      })
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err);
      });
  });

export default {
  get,
  post,
  patch,
  uploadFile,
  uploadFiles,
};
