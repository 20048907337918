const state = () => ({
  errors: [],
  loading: true,
  data: [],
  total: 0,
  totalUser: 0,
  search: "",
  group: "",
  status: "",
  sortBy: "lastActivity_DESC",
  statusCode: "",
  page: 1,
  perPage: "10",
  inProcess: false,
  options: {
    status: [{ name: "Eingeloggt", value: "ACTIVE" }],
    sortBy: [
      {
        name: "Sortierung: Aktivität (aufsteigend)",
        value: "lastActivity_DESC",
      },
      { name: "Sortierung: Aktivität (absteigend)", value: "lastActivity_ASC" },
      { name: "Sortierung: Verbunden (aufsteigend)", value: "createdAt_DESC" },
      { name: "Sortierung: Verbunden (absteigend)", value: "createdAt_ASC" },
      {
        name: "Sortierung: Mitglieds-Nr. (absteigend)",
        value: "membershipNumber_DESC",
      },
      {
        name: "Sortierung: Mitglieds-Nr. (aufsteigend)",
        value: "membershipNumber_ASC",
      },
    ],
    perPage: ["10", "20", "30", "40", "50", "100"],
  },
});

export default state;
