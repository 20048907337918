import { ifAuthenticated } from "@/router/auth";

const zdsWebsiteMagazines = [
  {
    path: "/zds/website/magazines",
    name: "ZDSWebsiteMagazines",
    component: () =>
      import("@/modules/zds/website/magazines/views/Magazines.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_website_magazines" },
  },
  {
    path: "/zds/website/magazines/create",
    name: "ZDSWebsiteMagazinesCreate",
    component: () =>
      import("@/modules/zds/website/magazines/views/MagazinesCreate.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_website_magazines" },
  },
  {
    path: "/zds/website/magazines/:id/edit",
    name: "ZDSWebsiteMagazinesEdit",
    component: () =>
      import("@/modules/zds/website/magazines/views/MagazinesEdit.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_website_magazines" },
  },
];

export default zdsWebsiteMagazines;
