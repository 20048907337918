const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setData(state, payload) {
    state.data = payload.data;
    state.total = payload.total;
    state.totalUser = payload.totalUser;
  },
  setErrors(state, errors) {
    state.errors = errors;
  },
  setInProcess(state, inProcess) {
    state.inProcess = inProcess;
  },
  setSearch(state, value) {
    state.search = value;
  },
  setStatus(state, value) {
    state.status = value;
  },
  setGroup(state, value) {
    state.group = value;
  },
  setSortBy(state, value) {
    state.sortBy = value;
  },
  setStatusCode(state, value) {
    state.statusCode = value;
  },
  setPage(state, value) {
    state.page = value;
  },
  setPerPage(state, value) {
    state.perPage = value;
  },
  setDefaults(state) {
    state.page = 1;
    state.perPage = "10";
    state.group = "";
    state.sortBy = "lastActivity_DESC";
    state.status = "";
    state.search = "";
    state.statusCode = "";
  },
};

export default mutations;
