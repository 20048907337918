import { ifAuthenticated } from "@/router/auth";

const zdsAppWorkingAids = [
  {
    path: "/zds/app/workingsaids",
    name: "ZDSAPPWorkingAids",
    component: () =>
      import("@/modules/zds/app/workingAids/views/WorkingAids.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_app_workingsaids" },
  },
  {
    path: "/zds/app/workingsaids/create",
    name: "ZDSAPPWorkingAidsCreate",
    component: () =>
      import("@/modules/zds/app/workingAids/views/WorkingAidsCreate.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_app_workingsaids" },
  },
  {
    path: "/zds/app/workingsaids/:id/edit",
    name: "ZDSAPPWorkingAidsEdit",
    component: () =>
      import("@/modules/zds/app/workingAids/views/WorkingAidsEdit.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_app_workingsaids" },
  },
];

export default zdsAppWorkingAids;
