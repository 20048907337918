import api from "@/api";

const actions = {
  uploadDocument({ dispatch, commit }, file) {
    return new Promise((resolve, reject) => {
      commit("setUploadInProcess", true);
      api
        .uploadFile("/v1/upload/zds/temp/document", file)
        .then((response) => {
          if (response.data.success) {
            commit("setUploadInProcess", false);
            resolve(response);
          } else {
            commit("setUploadInProcess", false);
            reject(response);
          }
        })
        .catch((err) => {
          dispatch("gobalError", err, { root: true });
        });
    });
  },
};

export default actions;
