import { ifAuthenticated } from "@/router/auth";

const zdsAdministrationContacts = [
  {
    path: "/zds/administration/contacts",
    name: "ZDSAdministrationContacts",
    component: () => import("@/modules/zds/administration/contacts/views/Contacts.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_administration_contacts" },
  },
  {
    path: "/zds/administration/contacts/create",
    name: "ZDSAdministrationContactsCreate",
    component: () => import("@/modules/zds/administration/contacts/views/ContactsCreate.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_administration_contacts" },
  },
  {
    path: "/zds/administration/contacts/:id/edit",
    name: "ZDSAdministrationContactsEdit",
    component: () => import("@/modules/zds/administration/contacts/views/ContactsEdit.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_administration_contacts" },
  },
];

export default zdsAdministrationContacts;
