import { ifAuthenticated } from "@/router/auth";

const zdsAdministrationLists = [
  {
    path: "/zds/administration/lists",
    name: "ZDSAdministrationLists",
    component: () =>
      import("@/modules/zds/administration/lists/views/Lists.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_administration_lists" },
  },
  {
    path: "/zds/administration/lists/create",
    name: "ZDSAdministrationListsCreate",
    component: () =>
      import("@/modules/zds/administration/lists/views/ListsCreate.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_administration_lists" },
  },
  {
    path: "/zds/administration/lists/:id/edit",
    name: "ZDSAdministrationListsEdit",
    component: () =>
      import("@/modules/zds/administration/lists/views/ListsEdit.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_administration_lists" },
  },
];

export default zdsAdministrationLists;
