import { ifAuthenticated } from "@/router/auth";

const zdsAppSessions = [
  {
    path: "/zds/app/sessions",
    name: "ZDSAppSessions",
    component: () => import("@/modules/zds/app/sessions/views/Sessions.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_app_sessions" },
  },
];

export default zdsAppSessions;
