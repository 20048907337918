const state = () => ({
  errors: [],
  inProcess: false,
  hints: {
    password: "",
    passwordRepeat: "",
  },
});

export default state;
