const mutations = {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setData(state, payload) {
    state.data = payload.data;
    state.total = payload.total;
  },
  setErrors(state, errors) {
    state.errors = errors;
  },
  setInProcess(state, inProcess) {
    state.inProcess = inProcess;
  },
  setLoadNewData(state, loading) {
    state.loadNewData = loading;
  },
  setSaveNewData(state, loading) {
    state.saveNewData = loading;
  },
  setNewErrors(state, errors) {
    state.newErrors = errors;
  },
  setLoadEditData(state, loading) {
    state.loadEditData = loading;
  },
  setSaveEditData(state, loading) {
    state.saveEditData = loading;
  },
  setEditErrors(state, errors) {
    state.editErrors = errors;
  },
};

export default mutations;
