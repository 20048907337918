const state = () => ({
  errors: [],
  loading: true,
  data: [],
  total: 0,
  search: "",
  status: "",
  group: "",
  sortBy: "lastLogin_DESC",
  page: 1,
  perPage: "10",
  inProcess: false,
  loadingRoles: false,
  loadingGroups: false,
  create: {
    loadingAccess: false,
    inProcess: false,
    errors: [],
  },
  edit: {
    loading: false,
    loadingAccess: false,
    inProcess: false,
    errors: [],
  },
  hints: {
    salutation: "",
    forename: "",
    surname: "",
    company: "",
    email: "",
    mobile: "",
    roles: "",
    groups: "",
    username: "",
    password: "",
    passwordRepeat: "",
  },
  options: {
    status: [
      { name: "Aktiviert", value: "ACTIVE" },
      { name: "Deaktiviert", value: "DISABLED" },
      { name: "Gesperrt", value: "BLOCKED" },
    ],
    sortBy: [
      {
        name: "Sortierung: Letzte Anmeldung (absteigend)",
        value: "lastLogin_DESC",
      },
      {
        name: "Sortierung: Letzte Anmeldung (aufsteigend)",
        value: "lastLogin_ASC",
      },
      { name: "Sortierung: Geändert (absteigend)", value: "updatedAt_DESC" },
      { name: "Sortierung: Geändert (aufsteigend)", value: "updatedAt_ASC" },
      { name: "Sortierung: Angelegt (absteigend)", value: "createdAt_DESC" },
      { name: "Sortierung: Angelegt (aufsteigend)", value: "createdAt_ASC" },
    ],
    perPage: ["10", "20", "30", "40", "50", "100"],
  },
});

export default state;
