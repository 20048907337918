const state = () => ({
  errors: [],
  loading: true,
  inProcess: false,
  data: [],
  hints: {
    salutation: "",
    forename: "",
    surname: "",
    company: "",
    email: "",
    mobile: "",
    username: "",
    oldPassword: "",
    newPassword: "",
    newPasswordRepeat: "",
  },
});

export default state;
