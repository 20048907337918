import addAttachment from "@/modules/zds/app/conferences/store/modal/addAttachment/";
import editAttachment from "@/modules/zds/app/conferences/store/modal/editAttachment/";
import addTimelineEntry from "@/modules/zds/app/conferences/store/modal/addTimelineEntry/";
import editTimelineEntry from "@/modules/zds/app/conferences/store/modal/editTimelineEntry/";

const state = () => ({});

const getters = {};

const actions = {};

const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
  modules: {
    addAttachment,
    editAttachment,
    addTimelineEntry,
    editTimelineEntry,
  },
};
