const state = () => ({
  errors: [],
  loading: true,
  data: [],
  total: 0,
  search: "",
  group: "",
  status: "",
  sortBy: "updatedAt_DESC",
  statusCode: "",
  memberStatus: "",
  page: 1,
  perPage: "10",
  inProcess: false,
  create: {
    inProcess: false,
    errors: [],
  },
  edit: {
    loading: false,
    inProcess: false,
    errors: [],
  },
  hints: {
    membershipNumber: "",
    autoPassword: "",
    password: "",
    passwordRepeat: "",
    type: "",
    group: "",
    promoCodes: "",
    memberStatus: "",
    external: "",
    statusCode: "",
    accession: "",
    salutation: "",
    title: "",
    gender: "",
    forename: "",
    surname: "",
    street: "",
    postcode: "",
    place: "",
    countryISOCode: "",
    email: "",
    anotherEmail: "",
    telephone: "",
    mobile: "",
    status: "",
    birthdayOrDateOfFoundation: "",
    company1: "",
    company2: "",
    company3: "",
    extra: "",
    localGroup: "",
    apprenticeshipBegin: "",
    apprenticeshipEnd: "",
    journeymanExamination: "",
    mastersExamination: "",
    functions: ""
  },
  options: {
    status: [
      { name: "Aktiviert", value: "ACTIVE" },
      { name: "Deaktiviert", value: "DISABLED" },
      { name: "Gelöscht", value: "DELETED" },
    ],
    sortBy: [
      { name: "Sortierung: Geändert (absteigend)", value: "updatedAt_DESC" },
      { name: "Sortierung: Geändert (aufsteigend)", value: "updatedAt_ASC" },
      { name: "Sortierung: Angelegt (absteigend)", value: "createdAt_DESC" },
      { name: "Sortierung: Angelegt (aufsteigend)", value: "createdAt_ASC" },
    ],
    perPage: ["10", "20", "30", "40", "50", "100"],
  },
});

export default state;
