const state = () => ({
  errors: [],
  inProcess: false,
  uploadInProcess: false,
  hints: {
    dateTime: "",
    headline: "",
    view: "",
    description: "",
  },
});

export default state;
