const state = () => ({
  errors: [],
  loading: false,
  uploadInProcess: false,
  data: [],
  total: 0,
  search: "",
  group: "",
  status: "",
  sendStatus: "",
  archived: "",
  sortBy: "updatedAt_DESC",
  page: 1,
  perPage: "5",

  inProcess: false,
  loadingLists: false,
  create: {
    inProcess: false,
    errors: [],
  },
  edit: {
    loading: false,
    inProcess: false,
    errors: [],
  },
  hints: {
    internalLabel: "",
    title: "",
    message: "",
    groups: "",
    sendOption: "",
    scheduledAt: "",
    statusCodes: "",
    status: "",
    zdsList: "",
    target: "",
    targetUrl: "",
    distribution: "",
    sendStatus: "",
  },
  options: {
    status: [
      { name: "Veröffentlicht", value: "ONLINE" },
      { name: "In Bearbeitung", value: "IN_PROCESS" },
      { name: "Entwurf", value: "DRAFT" },
      { name: "Ausgeblendet", value: "OFFLINE" },
    ],
    sendStatus: [
      { name: "Wartet", value: "WAITED" },
      { name: "In Bearbeitung", value: "IN_PROCESS" },
      { name: "Abgeschlossen", value: "COMPLETED" },
      { name: "Abgebrochen", value: "CANCELLED" },
    ],
    sortBy: [
      { name: "Sortierung: Geändert (absteigend)", value: "updatedAt_DESC" },
      { name: "Sortierung: Geändert (aufsteigend)", value: "updatedAt_ASC" },
      { name: "Sortierung: Angelegt (absteigend)", value: "createdAt_DESC" },
      { name: "Sortierung: Angelegt (aufsteigend)", value: "createdAt_ASC" },
      { name: "Sortierung: Zeitpunkt (absteigend)", value: "scheduledAt_DESC" },
      { name: "Sortierung: Zeitpunkt (aufsteigend)", value: "scheduledAt_ASC" },
    ],
    perPage: ["5", "10", "15", "20", "25", "50"],
  },
});

export default state;
