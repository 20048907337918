import api from "@/api";
import _ from "lodash";

const actions = {
  list({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit("setLoading", true);
      commit("setErrors", []);
      api
        .get("/v1/administration/users", data)
        .then((response) => {
          if (response.data.success) {
            commit("setData", response.data.payload);
            commit("setLoading", false);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((err) => {
          dispatch("gobalError", err, { root: true });
        });
    });
  },

  roles({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit("setLoadingRoles", true);
      api
        .get("/v1/administration/users/roles")
        .then((response) => {
          if (response.data.success) {
            commit("setLoadingRoles", false);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((err) => {
          dispatch("gobalError", err, { root: true });
        });
    });
  },

  access({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit("setCreateLoadingAccess", true);
      api
        .get("/v1/administration/users/access")
        .then((response) => {
          if (response.data.success) {
            commit("setCreateLoadingAccess", false);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((err) => {
          dispatch("gobalError", err, { root: true });
        });
    });
  },

  accessByUser({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      commit("setEditLoadingAccess", true);
      api
        .get(_.join(["/v1/administration/users", encodeURI(id),"access"], "/"))
        .then((response) => {
          if (response.data.success) {
            commit("setEditLoadingAccess", false);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((err) => {
          dispatch("gobalError", err, { root: true });
        });
    });
  },

  groups({ commit, dispatch }) {
    return new Promise((resolve, reject) => {
      commit("setLoadingGroups", true);
      api
        .get("/v1/administration/users/groups")
        .then((response) => {
          if (response.data.success) {
            commit("setLoadingGroups", false);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((err) => {
          dispatch("gobalError", err, { root: true });
        });
    });
  },

  create({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit("setCreateInProcess", true);
      commit("resetCreateErrors");
      api
        .post("/v1/administration/users", data)
        .then((response) => {
          if (response.data.success) {
            commit("setCreateInProcess", false);
            resolve(response);
          } else {
            const { errors } = response.data.payload;

            commit("setCreateErrors", {
              errors,
            });
            commit("setCreateInProcess", false);
            reject(response);
          }
        })
        .catch((err) => {
          commit("setCreateInProcess", false);
          dispatch("gobalError", err, { root: true });
        });
    });
  },

  resetCreateStepError({ state, commit }) {
    const { errors } = state.create;

    commit("setCreateErrors", {
      errors,
    });
  },

  show({ commit, dispatch }, id) {
    return new Promise((resolve, reject) => {
      commit("setEditInProcess", false);
      commit("setEditLoading", true);
      commit("setEditErrors", []);
      api
        .get(_.join(["/v1/administration/users", encodeURI(id)], "/"))
        .then((response) => {
          if (response.data.success) {
            commit("setEditLoading", false);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((err) => {
          dispatch("gobalError", err, { root: true });
        });
    });
  },

  update({ commit, dispatch }, payload) {
    const { id, data } = payload;

    return new Promise((resolve, reject) => {
      commit("setEditInProcess", true);
      commit("setEditErrors", []);
      api
        .patch(_.join(["/v1/administration/users", encodeURI(id)], "/"), data)
        .then((response) => {
          if (response.data.success) {
            commit("setEditInProcess", false);
            resolve(response);
          } else {
            commit("setEditErrors", response.data.payload.errors);
            commit("setEditInProcess", false);
            reject(response);
          }
        })
        .catch((err) => {
          commit("setEditInProcess", false);
          dispatch("gobalError", err, { root: true });
        });
    });
  },
  delete({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit("setInProcess", true);
      commit("setErrors", []);
      api
        .post("/v1/administration/users/delete", data)
        .then((response) => {
          if (response.data.success) {
            commit("setInProcess", false);
            resolve(response);
          } else {
            commit("setErrors", response.data.payload.errors);
            commit("setInProcess", false);
            reject(response);
          }
        })
        .catch((err) => {
          commit("setInProcess", false);
          dispatch("gobalError", err, { root: true });
        });
    });
  },
  activate({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit("setInProcess", true);
      commit("setErrors", []);
      api
        .post("/v1/administration/users/activate", data)
        .then((response) => {
          if (response.data.success) {
            commit("setInProcess", false);
            resolve(response);
          } else {
            commit("setErrors", response.data.payload.errors);
            commit("setInProcess", false);
            reject(response);
          }
        })
        .catch((err) => {
          commit("setInProcess", false);
          dispatch("gobalError", err, { root: true });
        });
    });
  },
  disable({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit("setInProcess", true);
      commit("setErrors", []);
      api
        .post("/v1/administration/users/disable", data)
        .then((response) => {
          if (response.data.success) {
            commit("setInProcess", false);
            resolve(response);
          } else {
            commit("setErrors", response.data.payload.errors);
            commit("setInProcess", false);
            reject(response);
          }
        })
        .catch((err) => {
          commit("setInProcess", false);
          dispatch("gobalError", err, { root: true });
        });
    });
  },
  logout({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit("setInProcess", true);
      commit("setErrors", []);
      api
        .post("/v1/administration/users/logout", data)
        .then((response) => {
          if (response.data.success) {
            commit("setInProcess", false);
            resolve(response);
          } else {
            commit("setErrors", response.data.payload.errors);
            commit("setInProcess", false);
            reject(response);
          }
        })
        .catch((err) => {
          commit("setInProcess", false);
          dispatch("gobalError", err, { root: true });
        });
    });
  },
};

export default actions;
