const state = () => ({
  errors: [],
  loading: true,
  data: [],
  total: 0,
  inProcess: false,
  create: {
    inProcess: false,
    errors: [],
  },
  edit:{
    inProcess: false,
    errors: [],
  }
});

export default state;
