import { ifAuthenticated } from "@/router/auth";

const zdsWebsiteDocuments = [
  {
    path: "/zds/website/documents",
    name: "ZDSWebsiteDocuments",
    component: () =>
      import("@/modules/zds/website/documents/views/Documents.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_website_documents" },
  },
  {
    path: "/zds/website/documents/create",
    name: "ZDSWebsiteDocumentsCreate",
    component: () =>
      import("@/modules/zds/website/documents/views/DocumentsCreate.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_website_documents" },
  },
  {
    path: "/zds/website/documents/:id/edit",
    name: "ZDSWebsiteDocumentsEdit",
    component: () =>
      import("@/modules/zds/website/documents/views/DocumentsEdit.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_website_documents" },
  },
];

export default zdsWebsiteDocuments;
