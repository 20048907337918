import { ifAuthenticated } from "@/router/auth";

const zdsWebsiteTutorials = [
  {
    path: "/zds/website/tutorials",
    name: "ZDSWebsiteTutorials",
    component: () =>
      import("@/modules/zds/website/tutorials/views/Tutorials.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_website_tutorials" },
  },
  {
    path: "/zds/website/tutorials/create",
    name: "ZDSWebsiteTutorialsCreate",
    component: () =>
      import("@/modules/zds/website/tutorials/views/TutorialsCreate.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_website_tutorials" },
  },
  {
    path: "/zds/website/tutorials/:id/edit",
    name: "ZDSWebsiteTutorialsEdit",
    component: () =>
      import("@/modules/zds/website/tutorials/views/TutorialsEdit.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_website_tutorials" },
  },
];

export default zdsWebsiteTutorials;
