import { ifAuthenticated } from "@/router/auth";

const zdsGenerallyNewsletter = [
  {
    path: "/zds/generally/newsletter",
    name: "ZDSGenerallyNewsletter",
    component: () =>
      import("@/modules/zds/generally/newsletter/views/Newsletter.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_generally_newsletter" },
  },
  {
    path: "/zds/generally/newsletter/create",
    name: "ZDSGenerallyNewsletterCreate",
    component: () =>
      import("@/modules/zds/generally/newsletter/views/NewsletterCreate.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_generally_newsletter" },
  },
  {
    path: "/zds/generally/newsletter/:id/edit",
    name: "ZDSGenerallyNewsletterEdit",
    component: () =>
      import("@/modules/zds/generally/newsletter/views/NewsletterEdit.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds_generally_newsletter" },
  },
];

export default zdsGenerallyNewsletter;
