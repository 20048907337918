import Vue from "vue";
import VueRouter from "vue-router";

import main from "@/router/main";

import zdsAdministrationMembers from "@/modules/zds/administration/members/router";
import zdsAdministrationContacts from "@/modules/zds/administration/contacts/router";
import zdsGenerallySweepDistricts from "@/modules/zds/generally/sweepdistricts/router";
import zdsGenerallyKlartext from "@/modules/zds/generally/klartext/router";
import zdsGenerallyNewsletter from "@/modules/zds/generally/newsletter/router";
import zdsWebsiteDocuments from "@/modules/zds/website/documents/router";
import zdsWebsiteMagazines from "@/modules/zds/website/magazines/router";
import zdsWebsiteTutorials from "@/modules/zds/website/tutorials/router";
import zdsAppWorkingAids from "@/modules/zds/app/workingAids/router";
import zdsAppSessions from "@/modules/zds/app/sessions/router";
import zdsAppConferences from "@/modules/zds/app/conferences/router";
import zdsAppMessages from "@/modules/zds/app/messages/router";
import zdsMediadataImages from "@/modules/zds/mediadata/images/router";
import zdsAdministrationLocalGroups from "@/modules/zds/administration/localGroups/router";
import zdsWebsiteBlogs from "@/modules/zds/website/blogs/router";
import zdsAdministrationLists from "@/modules/zds/administration/lists/router";

Vue.use(VueRouter);

const routes = [
  ...main,
  ...zdsAdministrationMembers,
  ...zdsAdministrationContacts,
  ...zdsGenerallySweepDistricts,
  ...zdsGenerallyKlartext,
  ...zdsGenerallyNewsletter,
  ...zdsWebsiteDocuments,
  ...zdsWebsiteMagazines,
  ...zdsWebsiteTutorials,
  ...zdsAppWorkingAids,
  ...zdsAppSessions,
  ...zdsMediadataImages,
  ...zdsAdministrationLocalGroups,
  ...zdsWebsiteBlogs,
  ...zdsAdministrationLists,
  ...zdsAppConferences,
  ...zdsAppMessages,
];

const router = new VueRouter({
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

export default router;
