import api from "@/api";
//import _ from "lodash";

const actions = {
  upload({ dispatch, commit }, files) {
    return new Promise((resolve, reject) => {
      commit("setInProcess", true);
      commit("setErrors", []);
      api
        .uploadFiles("/upload/zds/temp/images", files)
        .then((response) => {
          if (response.data.success) {
            commit("setInProcess", false);
            resolve(response);
          } else {
            commit("setErrors", response.data.payload.errors);
            commit("setInProcess", false);
            reject(response);
          }
        })
        .catch((err) => {
          dispatch("gobalError", err, { root: true });
        });
    });
  },
  load({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit("setLoading", true);
      commit("setErrors", []);
      api
        .post("/zds/mediadata/images", data)
        .then((response) => {
          if (response.data.success) {
            commit("setData", response.data.payload);
            commit("setLoading", false);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((err) => {
          dispatch("gobalError", err, { root: true });
        });
    });
  },

  new({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit("setSaveNewData", true);
      commit("setNewErrors", []);
      api
        .post("/zds/mediadata/images/new", data)
        .then((response) => {
          if (response.data.success) {
            commit("setSaveNewData", false);
            resolve(response);
          } else {
            commit("setNewErrors", response.data.payload.errors);
            commit("setSaveNewData", false);
            reject(response);
          }
        })
        .catch((err) => {
          dispatch("gobalError", err, { root: true });
        });
    });
  },

  loadEditData({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit("setLoadEditData", true);
      commit("setEditErrors", []);
      api
        .post("/zds/mediadata/images/load/edit", data)
        .then((response) => {
          if (response.data.success) {
            commit("setLoadEditData", false);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((err) => {
          dispatch("gobalError", err, { root: true });
        });
    });
  },

  edit({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit("setSaveEditData", true);
      commit("setEditErrors", []);
      api
        .post("/zds/mediadata/images/edit", data)
        .then((response) => {
          if (response.data.success) {
            commit("setSaveEditData", false);
            resolve(response);
          } else {
            commit("setEditErrors", response.data.payload.errors);
            commit("setSaveEditData", false);
            reject(response);
          }
        })
        .catch((err) => {
          dispatch("gobalError", err, { root: true });
        });
    });
  },
  delete({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit("setInProcess", true);
      commit("setErrors", []);
      api
        .post("/zds/mediadata/images/delete", data)
        .then((response) => {
          if (response.data.success) {
            commit("setInProcess", false);
            resolve(response);
          } else {
            commit("setErrors", response.data.payload.errors);
            commit("setInProcess", false);
            reject(response);
          }
        })
        .catch((err) => {
          dispatch("gobalError", err, { root: true });
        });
    });
  },
};

export default actions;
