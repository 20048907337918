import api from "@/api";

const actions = {
  list({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit("setLoading", true);
      commit("setErrors", []);
      api
        .get("/v1/zds/app/sessions", data)
        .then((response) => {
          if (response.data.success) {
            commit("setData", response.data.payload);
            commit("setLoading", false);
            resolve(response);
          } else {
            reject(response);
          }
        })
        .catch((err) => {
          dispatch("gobalError", err, { root: true });
        });
    });
  },

  close({ commit, dispatch }, data) {
    return new Promise((resolve, reject) => {
      commit("setInProcess", true);
      commit("setErrors", []);
      api
        .post("/v1/zds/app/sessions/close", data)
        .then((response) => {
          if (response.data.success) {
            commit("setInProcess", false);
            resolve(response);
          } else {
            commit("setErrors", response.data.payload.errors);
            commit("setInProcess", false);
            reject(response);
          }
        })
        .catch((err) => {
          commit("setInProcess", false);
          dispatch("gobalError", err, { root: true });
        });
    });
  },
};

export default actions;
