const state = () => ({
  errors: [],
  loading: false,
  uploadInProcess: false,
  data: [],
  total: 0,
  search: "",
  status: "",
  sortBy: "updatedAt_DESC",
  page: 1,
  perPage: "5",
  inProcess: false,
  create: {
    inProcess: false,
    errors: [],
  },
  edit: {
    loading: false,
    inProcess: false,
    errors: [],
  },
  hints: {
    internalLabel: "",
    headline: "",
    videoId: "",
    order: "",
    ownPreview: "",
    status: "",
  },
  options: {
    status: [
      { name: "Online", value: "ONLINE" },
      { name: "Offline", value: "OFFLINE" },
    ],
    sortBy: [
      { name: "Sortierung: Geändert (absteigend)", value: "updatedAt_DESC" },
      { name: "Sortierung: Geändert (aufsteigend)", value: "updatedAt_ASC" },
      { name: "Sortierung: Angelegt (absteigend)", value: "createdAt_DESC" },
      { name: "Sortierung: Angelegt (aufsteigend)", value: "createdAt_ASC" },
      { name: "Sortierung: Reihenfolge (absteigend)", value: "order_DESC" },
      { name: "Sortierung: Reihenfolge (aufsteigend)", value: "order_ASC" },
    ],
    perPage: ["5", "10", "15", "20", "25", "50"],
  },
});

export default state;
