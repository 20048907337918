const state = () => ({
  errors: [],
  loading: true,
  data: [],
  total: 0,
  search: "",
  group: "",
  status: "",
  type: "",
  vacant: "",
  sortBy: "updatedAt_DESC",
  page: 1,
  perPage: "10",
  inProcess: false,
  create: {
    inProcess: false,
    errors: [],
  },
  edit: {
    loading: false,
    inProcess: false,
    errors: [],
  },
  hints: {
    vacant: "",
    status: "",
    type: "",
    title: "",
    salutation: "",
    contact: "",
    mobile: "",
    email: "",
    group: "",
  },
  options: {
    vacant: [
      { name: "Ja", value: "YES" },
      { name: "Nein", value: "NO" },
    ],
    type: [
      { name: "Kreisgruppe", value: "COUNTY_GROUP" },
      { name: "Stadtbezirksgruppe", value: "DISTRICT_GROUP" },
    ],
    status: [
      { name: "Online", value: "ONLINE" },
      { name: "Offline", value: "OFFLINE" },
    ],
    sortBy: [
      { name: "Sortierung: Geändert (absteigend)", value: "updatedAt_DESC" },
      { name: "Sortierung: Geändert (aufsteigend)", value: "updatedAt_ASC" },
      { name: "Sortierung: Angelegt (absteigend)", value: "createdAt_DESC" },
      { name: "Sortierung: Angelegt (aufsteigend)", value: "createdAt_ASC" },
    ],
    perPage: ["10", "20", "30", "40", "50", "100"],
  },
});

export default state;
