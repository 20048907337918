import { ifNotAuthenticated, ifAuthenticated } from "@/router/auth";

const main = [
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/Login.vue"),
    beforeEnter: ifNotAuthenticated,
    meta: { moduleId: "login" },
  },
  {
    path: "/logout",
    name: "Logout",
    component: () => import("@/views/Logout.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "logout" },
  },
  {
    path: "/",
    name: "Home",
    component: () => import("@/views/Home.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "home" },
  },
  {
    path: "/profile",
    name: "Profile",
    component: () => import("@/views/Profile.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "profile" },
  },

  {
    path: "/administration/users",
    name: "AdministrationUsers",
    component: () => import("@/views/Administration/Users.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "administration_users" },
  },
  {
    path: "/administration/users/create",
    name: "AdministrationUsersAdd",
    component: () => import("@/views/Administration/UsersCreate.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "administration_users" },
  },
  {
    path: "/administration/users/:id/edit",
    name: "AdministrationUsersEdit",
    component: () => import("@/views/Administration/UsersEdit.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "administration_users" },
  },
  {
    path: "/zds",
    name: "ZDS",
    component: () => import("@/views/ZDS.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "zds" },
  },
  {
    path: "/hws",
    name: "HWS",
    component: () => import("@/views/HWS.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "hws" },
  },
  {
    path: "/sfv",
    name: "SFV",
    component: () => import("@/views/SFV.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "sfv" },
  },
  {
    path: "/innocenter",
    name: "INNOCENTER",
    component: () => import("@/views/INNOCENTER.vue"),
    beforeEnter: ifAuthenticated,
    meta: { moduleId: "innocenter" },
  },
  {
    path: "/noaccess",
    name: "NoAccess",
    component: () => import("@/views/NoAccess.vue"),
    meta: { moduleId: "noaccess" },
  },
  {
    path: "*",
    name: "NotFound",
    component: () => import("@/views/NotFound.vue"),
    meta: { moduleId: "notfound" },
  },
];

export default main;
